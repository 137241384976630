@import './variables';

.no-account {
  .no-account-divider {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: space-between;
    align-items: center;

    .divider-line {
      border-top: 1px solid $light70;
      width: 40%;
    }
    .divider-text {
      font-weight: bold;
      line-height: 18px;
      color: $light70;
      text-transform: uppercase;
    }
  }
}
