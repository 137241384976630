@charset "utf-8";

$font-family-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$enable-negative-margins: true;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$font-sizes: map-merge($font-sizes, (
  7: 0.875rem,
));

$font-weight-bolder: 600;


$theme-colors: map-merge($theme-colors, (
  "kblue": #224572,
  "success": #4BB461,
));

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

// Modal settings
$modal-footer-margin-between: 0;
$modal-inner-padding: 1.25rem;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;
$modal-content-border-width: 0;
$modal-md: 445px;

// Button settings
$btn-padding-y: 0.625rem;
$btn-link-color: #224572;
$btn-link-hover-color: darken(#224572, 5%);

// Form settings
$form-label-font-weight: 600;

// Enable negative margins


@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/alert";

@import "./styles/mixins";

$fa-font-path:"../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

.btn-koosmik {
  @include button-variant(
      #224572,
      #224572,
      #fff,
      #159E64,
      #159E64,
      #fff
  );

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(#159E64, .5);
  }
}

.btn-dismiss {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: -6px;
  background-color: transparent;
  border: none;
  color: #224572;
  margin-left: auto;

  &:hover {
    background-color: #EFF4F9;
    border-radius: 50%;
  }

  img {
    width: 20px;
    height: auto;
  }
}

.btn-link {
  &:hover {
    background-color: darken(#EFF4F9, 2.5%);
  }
}

a {
  font-weight: 600;
}

.modal-title {
  line-height: 1;

  img {
    height: 28px;
    width: auto;
  }
}

.header-merchant {
  display: flex;
  align-items: center;
  margin-left: 10px;

  img {
    height: 24px;
    width: auto;
    flex: 0 auto;
  }

  &::before {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    margin-right: 10px;
    background-color: #224572;
  }
}

.modal-footer {
  background-color: #EFF4F9;
}

.modal-content {
  box-shadow: 0 4px 20px -10px rgba(#000, 0.5);
}

.modal-cart {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-top: solid 1px #E0E8F1;

  .label {
    margin-right: 5px;
    font-size: 0.625rem;
    font-weight: 600;
    color: #8396AB;
    text-transform: uppercase;
  }

  .amount {
    font-size: 0.875rem;
    font-weight: 700;
  }
}

.modal-user-cart {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  border-top: solid 1px #E0E8F1;

  .fa {
    margin-right: 4px;
    width: 14px;
    text-align: center;
  }

  .user {
    font-size: 0.75rem;
    font-weight: 600;
    color: #224572;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .amount-wrapper {
    flex: 1 0 auto;
    padding-left: 5px;
  }

  .label {
    font-size: 0.625rem;
    font-weight: 600;
    color: #8396AB;
    text-transform: uppercase;
  }

  .amount {
    margin-left: 5px;
    font-size: 0.875rem;
    font-weight: 700;
  }
}

.modal-heading {
  margin-left: -$modal-inner-padding;
  margin-right: -$modal-inner-padding;
  margin-top: -$modal-inner-padding;
  background-color: #EFF4F9;
  color: #224572;
  font-size: 1.125rem;
  font-weight: 600;
}

.modal-steps {
  padding: 0;
  // margin-left: -$modal-inner-padding;
  // margin-right: -$modal-inner-padding;
  margin-bottom: 0;
  list-style-type: none;
  height: 45px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(45deg, #0D9F76 0%, #3E7C8D 25%, #2D5A88 100%);
  color: #fff;
}

.step {
  display: flex;
  align-items: center;
  line-height: 1;
  opacity: 0.75;

  .fa {
    font-size: 18px;
    flex: 0 0 auto;
    min-width: 20px;
    margin-top: 3px;
    text-align: center;
  }

  &.current {
    opacity: 1;

    .fa {
      font-size: 1rem;
      text-shadow: 0 1px 1px rgba(#000, 0.25);
    }
  }

  &:not(:last-of-type) {
    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 20px;
      margin: 0 5px;
      background-color: #fff;
    }
  }

}

.step-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 4px;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(#000, 0.25);
  }
}

.cart {
  .label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #8396AB;
    text-transform: uppercase;
  }

  .amount {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.panel {
  padding: 20px;
  border: solid 1px #E0E8F1;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(#000, 0.05);
}

.help-text {
  font-size: 0.875rem;
  color: #224572;
}

.number-code {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > legend {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .code-input input {
    height: 48px;
    width: 40px;
    padding: 0;
    flex: 0 auto;
    text-align: center;
    font-size: 1.125rem;

    &:not(:last-of-type) {
      margin-right: 6px;
    }

    &:nth-of-type(3) {
      margin-right: 16px;
    }
  }
}

.recap-message {
  font-size: 1.125rem;
  font-weight: 600;
  color: #224572;
}

.payment-table {
  margin-left: -$modal-inner-padding;
  margin-right: -$modal-inner-padding;
  margin-top: -$modal-inner-padding;
  background-color: #EFF4F9;
}

.payment-table-row {
  padding: 10px;
  border-bottom: solid 1px #C0D1E3;
  line-height: 1;

  .th {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .negative {
    &::before {
      content: "—";
      margin-right: 5px;
      color: #BE3C4A;
    }
  }

  .miles-line {
    display: flex;
    align-items: center;
    &:after {
      @include svg("miles-icon");
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      margin-left: 0.2rem;
    }
  }
}

.payment-method-item-wrapper {
  position: relative;

  input[type="radio"] {
    position: absolute;
    right: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.payment-method-item {
  display: flex;
  min-height: 65px;
  padding: 10px (15px + 18px) 10px 15px;
  align-items: center;
  background-color: #fff;
  border: solid 1px #C0D1E3;
  box-shadow: 0 2px 8px -6px rgba(#000, 0.5);
  border-radius: 6px;
  line-height: 1.25;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: none;
  }

  .icon {
    width: 32px;
    text-align: center;
    margin-right: 10px;
  }

  &::after {
    content: "";
    display: block;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: #fff;
    border: solid 1px #C0D1E3;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -9px;
  }

  input[type="radio"]:checked + & {
    background-color: #E6FAEA;
    box-shadow: 0 0 0 1px #4BB461 inset;
    border-color: #4BB461;

    &::after {
      content: "\f058";
      font-family: "Font Awesome 5 Free";
      font-size: 18px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4BB461;
      border-color: transparent;
    }
  }

  input[type="radio"]:disabled + & {
    background-color: #EFF4F9;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    &::after {
      content: none;
    }
  }
}

.payment-method-add {
  &::after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #224572;
  }

  input[type="radio"]:checked + & {
    background-color: #E6FAEA;
    box-shadow: 0 0 0 1px #224572 inset;
    border-color: #224572;
    height: auto;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &::after {
      content: "\f067";
      color: #4BB461;
      background-color: transparent;
    }
  }
}

.cc-input-wrapper {
  position: relative;
}

.cc-input-brands {
  position: absolute;
  bottom: 0.75rem;
  right: 10px;
  display: flex;
  align-items: center;

  img {
    margin-left: 5px;
    filter: grayscale(1);
    opacity: 0.5;

    &.current {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}

.cc-number {
  font-family: "Source Code Pro", monospace;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.payment-method-add-cc {
  border: solid #224572;
  border-width: 0 2px 2px;
  border-radius: 0 0 6px 6px;

  padding: 15px;
}

@include media-breakpoint-only(xs) {
  .modal-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .modal-heading {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .number-code {
    .code-input {
      height: 46px;
      width: 38px;
    }
  }

  .payment-table {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .payment-method-cc {
    padding-left: 10px;

    .icon {
      margin-right: 8px;
    }
  }

  .cc-number {
    letter-spacing: -1.5px;
  }

  .payment-table + .alert {
    margin-top: 0.5rem;
  }
}



#main-modal {
  display: block;
}

.merchant-name {
  margin-left: 0.5rem;
  font-weight: bold;
}

.ml-text {
  margin-left: 5px;
}

.payment-table + .alert {
  margin-top: $modal-inner-padding/2;
}

//$colors: null;

// import Fonts
@import "./styles/fonts";

// Import bulma custom
//@import "./styles/bulma-custom";

//// Import modules
@import "styles/base";
@import "styles/app-loading";
@import "styles/embedded";
@import "styles/widget";
@import "styles/auth";
//@import "styles/dashboard";
//
