@import './variables';

html, body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($dark30, 0.85);
}
