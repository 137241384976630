@import './variables';
@import './mixins';

.birthday {
  display: flex;

  .day {
    flex: 0 0 8rem;
  }
  .month {
    flex: 1 0 12rem;
  }
  .year {
    flex: 0 0 8rem;
  }
}

.modal-content.success, .modal-content.error {
  color: $light;

  &.success {
    @include positiveGradient;
  }

  &.error {
    @include errorGradient;
  }

  h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.15rem;
  }

  &.success .modal-body:after {
    display: block;
    content: "";
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    margin: 1rem auto 0;
    background-color: $light;
    @include svg("loader");
    background-size: 1rem;
    animation: rotate 1s infinite;
  }
}
