@import './variables';
@import './mixins';
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

.embedded {
  width: 36rem;
  margin: 0 auto;
  box-shadow: 0 1rem 2rem 0 rgba($dark80, 0.2);
  overflow: hidden;

  .merchant-info {
    background: $primary;
    @include primaryGradient;

    .card-header-title {
      color: $light;
    }

    .cover {
      display: flex;
      justify-content: center;
      padding: 1rem;

      .cover-placeholder {
        width: 64px;
        height: 64px;
        border-radius: 3.25rem;
        background-color: $light;
        @include svg("shop");
        background-size: 2rem;
      }
    }
  }

  > .alert {
    padding: 0.5rem;
    &.is-danger {
      background-color: $danger;
      color: $danger-invert;
    }
    &.is-warning {
      background-color: $warning;
      color: $warning-invert;
    }
  }
}

@include until($tablet) {
  .embedded {
    position: absolute;
    top: 7.75rem;
    bottom: 3rem;
    right: 0;
    width: 100%;
    background-color: $light40;
    overflow: auto;
  }
}
