@font-face {
  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/assets/fonts/SourceSansPro-Regular.eot') format('embedded-opentype'),
  url('../resources/assets/fonts/SourceSansPro-Regular.ttf.woff2') format('woff2'),
  url('../resources/assets/fonts/SourceSansPro-Regular.otf.woff') format('woff'),
  url('../resources/assets/fonts/SourceSansPro-Regular.otf') format('opentype'),
  url('../resources/assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/assets/fonts/SourceSansPro-Semibold.eot') format('embedded-opentype'),
  url('../resources/assets/fonts/SourceSansPro-Semibold.ttf.woff2') format('woff2'),
  url('../resources/assets/fonts/SourceSansPro-Semibold.otf.woff') format('woff'),
  url('../resources/assets/fonts/SourceSansPro-Semibold.otf') format('opentype'),
  url('../resources/assets/fonts/SourceSansPro-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/assets/fonts/SourceSansPro-Bold.eot') format('embedded-opentype'),
  url('../resources/assets/fonts/SourceSansPro-Bold.ttf.woff2') format('woff2'),
  url('../resources/assets/fonts/SourceSansPro-Bold.otf.woff') format('woff'),
  url('../resources/assets/fonts/SourceSansPro-Bold.otf') format('opentype'),
  url('../resources/assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Code Pro';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/assets/fonts/SourceCodePro-Regular.eot') format('embedded-opentype'),
  url('../resources/assets/fonts/SourceCodePro-Regular.ttf.woff2') format('woff2'),
  url('../resources/assets/fonts/SourceCodePro-Regular.otf.woff') format('woff'),
  url('../resources/assets/fonts/SourceCodePro-Regular.otf') format('opentype'),
  url('../resources/assets/fonts/SourceCodePro-Regular.ttf') format('truetype');
}
