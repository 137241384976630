/* Koosmik colors swatches */

$primary: #2C5885;
$primary50: #678AAE;
$secondary: #00AB77;
$gradientPrimary: #2D5A88;
$gradientMerger: #3E7C8D;
$gradientSecondary: #0D9F76;
$gradientPositivePrimary: #4B9A9F;
$gradientPositiveSecondary: #59BE74;
$light: #FFFFFF;
$light20: #f6f7f9;
$light30: #E6EDF4;
$light40: #E2E7EC;
$light70: #95A7B9;
$dark30: #586675;
$dark70: #41414c;
$dark80: #2a2a31;
$dark100: #0b0b0d;
$positive: #3CC18D;
$positive100: #00AB77;
$negative: #dd6f6f;
$negative100: #B34A48;

/* Fonts */

$font-definition : "Source Sans Pro", "Helvetica", "Segoe UI", "Verdana", sans-serif;
$font-mono : "Source Code Pro", "Menlo", "Courrier", monospace;
