@import 'variables';

@mixin primaryGradient() {
  background: (linear-gradient(-140deg, $gradientPrimary, $gradientMerger 70%, $gradientSecondary));
}

@mixin positiveGradient() {
  background: (linear-gradient(-140deg, $gradientPositiveSecondary, $gradientPositivePrimary));
}

@mixin errorGradient() {
  background: (linear-gradient(-140deg, #C03944, #435070 70%, #2C5885));
}

@mixin svg($image, $alignment: center center) {
  background-image: url("../resources/assets/images/" + $image + ".svg");
  background-repeat: no-repeat;
  background-position: $alignment;
}

@mixin noText {
  color: transparent;
  font: 0/0 a;
  text-indent: -100%;
}

@mixin userSelect {
  -webkit-user-select: none;
  user-select: none;
}
