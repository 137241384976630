@import './variables';
@import './mixins';
@import './animations';

.app-loading {
  height: 4rem;
  width: 4rem;
  background: $light;
  box-shadow: 0 0 2rem 0 rgba($dark80, 0.5);
  border-radius: 4rem;
  padding: 1rem;
  transition: all 20ms cubic-bezier(0.19, 1, 0.22, 1);
  @include noText;

  &:before {
    display: block;
    content: "";
    height: 2rem;
    width: 2rem;
    @include svg("loader");
    background-size: 2rem;
    animation: rotate 1s infinite;
  }
}
